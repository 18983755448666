#selliePopup {
  position: fixed;
  bottom: 10px;
  right: 10px;
  z-index: 100;
  width: 300px;
}

#sellie-img {
  width: 80%;
}

#closeSelliePopup {
  position: absolute;
  top: 10px;
  right: 10px;
  background: transparent;
  border: none;
  color: #000;
  font-size: 1.5rem;
}
