@import url("https://fonts.googleapis.com/css2?family=Manrope:wght@200;300;400;500;600;700&display=swap");
@import url("https://cdnjs.cloudflare.com/ajax/libs/font-awesome/4.7.0/css/font-awesome.min.css");

:root {
  --hpl-color-primary: #001bbe;
  --hpl-color-background: #f5f5fd;
  --swiper-theme-color: #001bbe !important;
  --swiper-navigation-size: 1.5rem !important;
}

.wrapper {
  display: grid;
  grid-template-columns: 2fr 8fr;
}

/* body {
  background-color: var(--hpl-color-background) !important;
} */

thead button.ascending::after {
  font-family: FontAwesome;
  content: "\f0de";
  display: inline-block;
  margin-left: 1em;
}

thead button.descending::after {
  font-family: FontAwesome;
  content: "\f0dd";
  display: inline-block;
  margin-left: 1em;
}
thead button::after {
  font-family: FontAwesome;
  content: "\f0dc";
  display: inline-block;
  margin-left: 1em;
}

.swiper-button-next,
.swiper-button-prev {
  height: 1rem;
}

.btn-link {
  background-color: transparent;
  border: none;
  cursor: pointer;
  text-decoration: none;
  display: inline;
  margin: 0;
  padding: 0;
}

.react-select.react-select-primary
  .react-select__control.react-select__control--is-disabled {
  pointer-events: auto;
  cursor: not-allowed;
}
